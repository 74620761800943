import {
  reactive,
  toRefs,
  getCurrentInstance,
  computed,
  watch,
  ref,
  onBeforeUnmount
} from 'vue'
import { useStore } from 'vuex'
import { niceForm } from '@/common/form/NiceForm'
import { useRouter } from 'vue-router'
import { fnModal } from '@/components/common/modal'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = () => {
  const prof = ref(null) // ref
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const router = useRouter()
  const detailForm = getters['user/getDetailForm']
  const state = reactive({
    bankList: [], // 은행코드 리스트
    userData: computed(() => getters['user/getData']),
    detailForm: { ...detailForm },
    confirmPw: '',
    inputFile: '',
    file: '',
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const { copyCliboard } = CommonFunction(state)

  const fnGetCode = async () => {
    const res = await proxy.$MyPageSvc.postMyUserInfoCreateCode()
    if(res.resultCode === '0000') {
      state.detailForm.code = res.code
    } else {
      alert(res.resultMsg)
    }
  }

  const fnBankList = async () => {
    try {
      const res = await proxy.$CommonSvc.postBankList()
      state.bankList = res.list
      const head = proxy.$_.head(state.bankList)
      if (
        !proxy.$Util.isEmpty(head) &&
        proxy.$Util.isEmpty(state.detailForm.bankCode)
      ) {
        state.detailForm.bankCode = head.bankCode
      }
    } catch (err) {
      console.error(err)
    }
  }
  const fnNickDupl = async () => {
    try {
      const params = {}
      params.nick = state.detailForm.nick
      const res = await proxy.$UserSvc.postCheckDuplByNick(params)
      alert(res.resultMsg)
      if (res.resultCode === '0000') {
        state.detailForm.isNickDupl = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
      } else {
        state.detailForm.isNickDupl = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
      }
      await dispatch('user/updateDetailForm', {
        detailForm: state.detailForm
      })
    } catch (e) {
      console.error(e)
    }
  }
  const fnPhoneAuth = async (callType = 1) => {
    try {
      await dispatch('user/updateDetailForm', {
        detailForm: { ...state.detailForm }
      })
      const params = {}
      params.callType = callType
      params.rtnUrl = state.rtnUrl
      params.failRtnUrl = state.failRtnUrl
      const res = await proxy.$UserSvc.postAuthNiceAuthPop(params)
      if (proxy.$Util.isEmpty(res.niceData)) {
        alert(res.niceMessage)
        return
      }
      state.niceForm.encodeData = res.niceData
    } catch (err) {
      console.error(err)
    }
  }
  const fnCallback = async () => {
    state.detailForm = { ...getters['user/getDetailForm'] }
  }
  const fnChange = async event => {
    // if (typeof event.target.files[0] === 'object') {
    //   state.file = event.target.files[0]
    // } else {
    //   state.file = ''
    // }
    if (typeof event.target.files[0] === 'object') {
      const maxSize = 3 * 1024 * 1024
      const fileSize = event.target.files[0].size
      if (fileSize > maxSize) {
        alert('첨부파일은 3MB 미만의 용량으로 등록해주세요.')
        // state.file = ''
        // prof.value.src = ''
      } else {
        state.inputFile = event.target.files[0]
      }
    } else {
      state.file = ''
      prof.value.src = ''
    }
  }
  const fnSave = async () => {
    if (!isValid()) return
    const request = {
      pw: state.detailForm.pw,
      nick:
        state.detailForm.isNickDupl !== proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          ? ''
          : state.detailForm.nick,
      accountNum: state.detailForm.accountNum,
      bankCode: state.detailForm.bankCode,
      wizzTalk: state.detailForm.wizzTalk,
      hopeParty: state.detailForm.hopeParty,
      partyPostUpdate: state.detailForm.partyPostUpdate,
      smsAgree: state.detailForm.smsAgree,
      emailAgree: state.detailForm.emailAgree
    }
    const params = {
      request: JSON.stringify(request),
      file: state.file
    }
    const res = await proxy.$MyPageSvc.postMyUserInfoUpdate(params)
    if (res.resultCode === '0000') {
      await dispatch('user/signIn', {
        data: proxy.$_.merge({}, state.userData, res.info)
      })
      alert(res.resultMsg)
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnWithdrawalCheck = async () => {
    const res = await proxy.$MyPageSvc.postWithdrawalCheck()
    if (res.resultCode === '0000') {
      // 탈퇴가능
      if (
        !confirm(
          '탈퇴시 기존 마이페이지의 거래 정보 및 파티 정보, 보유중인 포인트는 모두 초기화 됩니다. 정말 탈퇴하시겠습니까?'
        )
      )
        return
      const res1 = await proxy.$MyPageSvc.postWithdrawal()
      if (res1.resultCode === '0000') {
        alert('위즈니를 이용해 주셔서 감사합니다.')
        await dispatch('user/signOut')
        location.replace('/')
      } else {
        alert(res1.resultMsg)
      }
    } else {
      // 탈퇴불가
      alert(res.resultMsg)
    }
  }
  const maskingAccountNum = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 40) {
      e.target.value = e.target.value.substr(0, 40)
    }
    state.detailForm.accountNum = String(e.target.value)
  }
  const isValid = () => {
    // 패스워드 유효성 체크기준
    // 1. 입력을 한경우
    if (!proxy.$Util.isEmpty(state.detailForm.pw)) {
      if (state.detailForm.pw !== state.confirmPw) {
        alert('비밀번호가 일치하지 않습니다. 비밀번호를 다시 입력해주세요.')
        return false
      }
    }
    // 닉네임 유효성 체크기준
    // 1. 현재 닉네임과 다른경우
    if (state.detailForm.nick !== state.userData.nick) {
      if (state.detailForm.isNickDupl !== proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
        alert('닉네임 중복체크를 해주세요.')
        return false
      }
    }
    // if (proxy.$Util.isEmpty(state.detailForm.accountNum)) {
    //   alert('계좌번호를 입력해주세요.')
    //   return false
    // }
    return true
  }
  const fnModalCropper = async (src, fileName) => {
    const payload = {
      component: proxy.$modalNames.CROPPER,
      data: {
        img: src,
        fileName: fileName
      },
      callback: (src, file) => {
        prof.value.src = src
        state.file = file
      }
    }
    await fnModal(payload)
  }
  /** watch **/
  watch(
    () => state.inputFile,
    val => {
      if (typeof val === 'object') {
        const reader = new FileReader()

        reader.onload = async event => {
          await fnModalCropper(event.target.result, val.name)
          // prof.value.src = event.target.result
        }
        reader.readAsDataURL(val)
      } else {
        prof.value.src = ''
      }
    }
  )
  watch(
    () => state.detailForm.nick,
    () => {
      // 변경시 마다 중복체크 다시 필요
      state.detailForm.isNickDupl = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
    }
  )
  watch(
    () => state.detailForm,
    async val => {
      await dispatch('user/updateDetailForm', {
        detailForm: val
      })
    },
    { deep: true }
  )
  onBeforeUnmount(async () => {
    await dispatch('user/clearDetailForm')
  })
  /** init **/
  const init = async () => {
    if (proxy.$Util.isEmpty(state.detailForm.id)) {
      await router.go(-1)
    }
    await fnBankList()
  }
  init()
  return {
    prof,
    ...toRefs(state),
    fnNickDupl,
    fnPhoneAuth,
    fnCallback,
    fnChange,
    fnSave,
    fnWithdrawalCheck,
    maskingAccountNum,
    copyCliboard,
    fnGetCode
  }
}
