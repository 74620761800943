<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>회원 정보</span>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">아이디(이메일)</div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="bind">
                        <span>{{ detailForm.id }}</span>
                        <div
                          class="sv_tag"
                          :class="[
                            { kakao: Number(userData.loginType) === 0 },
                            { naver: Number(userData.loginType) === 1 },
                            { google: Number(userData.loginType) === 2 },
                            { apple: Number(userData.loginType) === 3 }
                          ]"
                          v-if="!$Util.isEmpty(userData.loginType)"
                        >
                          {{
                            Number(userData.loginType) === 0
                              ? '카카오'
                              : Number(userData.loginType) === 1
                              ? '네이버'
                              : Number(userData.loginType) === 2
                              ? '구글'
                              : '애플'
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">휴대폰 인증</div>
                  <div class="form_in">
                    <div
                      class="inline"
                      v-if="
                        Number(detailForm.authType) === 2 ||
                          Number(detailForm.authType) === 4
                      "
                    >
                      <div class="bind">
                        <span class="color" v-if="detailForm.niceCnt < 1">{{
                          `${detailForm.phone} ${detailForm.name} 인증되었습니다.`
                        }}</span>
                        <span class="color" v-else>{{
                          `${$Util.getPhoneFormat(detailForm.phone, 1)} ${
                            detailForm.name
                          } 재인증되었습니다.`
                        }}</span>
                      </div>
                      <button
                        class="btn"
                        @click="
                          fnPhoneAuth($ConstCode.NICE_CALL_TYPE.RE_AUTH.value)
                        "
                      >
                        재인증
                      </button>
                    </div>
                    <div class="inline" v-else>
                      <div class="bind">
                        <span class="color">미인증</span>
                      </div>
                      <button
                        class="btn"
                        @click="
                          fnPhoneAuth(
                            $ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
                          )
                        "
                      >
                        인증
                      </button>
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">비밀번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 입력해 주세요."
                        v-model="detailForm.pw"
                      />
                    </label>
                    <div class="hint">
                      영문, 숫자, 특수문자를 모두 포함하여 8자~12자 이내로
                      입력해 주세요.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">비밀번호 확인</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 한번 더 입력해 주세요."
                        v-model="confirmPw"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">닉네임</div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="input">
                        <input
                          type="text"
                          placeholder="닉네임을 입력해 주세요."
                          v-model="detailForm.nick"
                        />
                      </label>
                      <button class="btn" @click="fnNickDupl">중복체크</button>
                    </div>
                    <div class="hint">
                      한글 2~8자, 영문/숫자 4~16자 이내로 입력해 주세요.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">추천인 코드</div>
                  <div v-if="detailForm.code" class="form_in">
                    <label
                      class="input"
                      @click="copyCliboard(detailForm.code, '추천인 코드')"
                    >
                      <input
                        type="text"
                        class="disabled"
                        disabled
                        v-model="detailForm.code"
                      />
                    </label>
                  </div>
                  <div v-else class="form_in">
                    <button class="btn" @click="fnGetCode">추천인 코드 받기</button>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">회원 섬네일</div>
                  <div class="form_in">
                    <label class="input_file">
                      <input
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        @change="fnChange"
                        onclick="this.value = ''"
                      />
                      <span class="label bo">파일 선택</span>
                    </label>
                    <div
                      class="input_file_name"
                      v-show="
                        (!$Util.isEmpty(detailForm.profile) &&
                          detailForm.profile.indexOf('/null') < 0) ||
                          file
                      "
                    >
                      <div class="prof">
                        <div class="img_area">
                          <img :src="detailForm.profile" alt="" ref="prof" />
                        </div>
                      </div>
                      <span>{{
                        file.name || detailForm.profile.split('/').reverse()[0]
                      }}</span>
                    </div>
                    <div class="hint">
                      PNG, JPG or GIF 이미지만 사용 가능합니다.
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">계좌 등록</div>
                  <div class="form_in">
                    <div class="inline">
                      <label class="select">
                        <select v-model="detailForm.bankCode">
                          <option
                            v-for="item in bankList"
                            :key="item.bankCode"
                            :value="item.bankCode"
                            >{{ item.bankNm }}</option
                          >
                        </select>
                      </label>
                      <label class="input">
                        <input
                          type="number"
                          inputmode="decimal"
                          pattern="\d*"
                          placeholder="숫자만 입력(40자 이내)"
                          @input="maskingAccountNum"
                          :value="detailForm.accountNum"
                        />
                      </label>
                    </div>
                    <label class="input">
                      <input
                        type="text"
                        placeholder="예금주 명을 입력해 주세요."
                        v-model="detailForm.accountHolder"
                        disabled
                      />
                    </label>
                    <div class="hint color_error">
                      * 계좌 정보 오류시 출금이 지연되므로, 다시 한번 확인해주세요.
                    </div>
                    <div class="hint">
                      * 파티장(판매자) 캐시를 현금 변환 시 계좌 정보가
                      필요합니다.
                    </div>
                    <div class="hint">
                      * 반드시 인증을 받은 본인 명의의 계좌로만 등록해 주세요.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="on_list">
              <ul class="on_list_ul">
                <li class="on_list_li">
                  <div class="on_list_ttl">위즈톡 알림</div>
                  <label class="toggle_type02"
                    ><input
                      type="checkbox"
                      :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                      :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                      v-model="detailForm.wizzTalk"
                      :checked="
                        detailForm.wizzTalk === $ConstCode.BOOLEAN_VALUE.TRUE
                      "/><span></span
                  ></label>
                </li>
                <li class="on_list_li">
                  <div class="on_list_ttl">희망 파티 설정</div>
                  <label class="toggle_type02"
                    ><input
                      type="checkbox"
                      :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                      :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                      v-model="detailForm.hopeParty"
                      :checked="
                        detailForm.hopeParty === $ConstCode.BOOLEAN_VALUE.TRUE
                      "/><span></span
                  ></label>
                </li>
                <li class="on_list_li">
                  <div class="on_list_ttl">파티 게시글 업데이트</div>
                  <label class="toggle_type02"
                    ><input
                      type="checkbox"
                      :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                      :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                      v-model="detailForm.partyPostUpdate"
                      :checked="
                        detailForm.partyPostUpdate ===
                          $ConstCode.BOOLEAN_VALUE.TRUE
                      "/><span></span
                  ></label>
                </li>
                <li class="on_list_li">
                  <div class="on_list_ttl">SMS 마케팅 정보 알림</div>
                  <label class="toggle_type02"
                    ><input
                      type="checkbox"
                      :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                      :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                      v-model="detailForm.smsAgree"
                      :checked="
                        detailForm.smsAgree === $ConstCode.BOOLEAN_VALUE.TRUE
                      "/><span></span
                  ></label>
                </li>
                <li class="on_list_li">
                  <div class="on_list_ttl">E-mail 마케팅 정보 알림</div>
                  <label class="toggle_type02"
                    ><input
                      type="checkbox"
                      :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                      :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                      v-model="detailForm.emailAgree"
                      :checked="
                        detailForm.emailAgree === $ConstCode.BOOLEAN_VALUE.TRUE
                      "/><span></span
                  ></label>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn pd_b2">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnWithdrawalCheck">탈퇴</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice3'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-info-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
