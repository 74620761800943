import store from '@/store'

export const fnModal = async ({
  component = {},
  data = {},
  callback = () => {}
}) => {
  const payload = {}
  payload.component = component
  payload.data = data
  payload.callback = callback
  await store.dispatch('layout/pushModalComponent', payload)
}
